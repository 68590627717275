import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../api";
import getMemberQueryKeys from "../../members/query-keys";
import { transformAPIError } from "../../utils";
import getAppointmentQueryKeys from "../query-keys";

interface BulkCancelPayload {
  appointmentIds: number[];
  memberUserId: number;
  reason: string;
  reasonDetails: string;
  additionalNotes: string;
  notifyMember: boolean;
}

interface BulkCancelResponse {
  cancelled_appointment_ids: number[];
  failed_cancellations: {
    appointment_id: number;
    reason: string;
  }[];
}

const bulkCancel = (payload: BulkCancelPayload, accessToken: string) =>
  api
    .post<BulkCancelResponse>(
      "/v1/therapy/appointments/cancel",
      {
        appointment_ids: payload.appointmentIds,
        member_user_id: payload.memberUserId,
        reason: payload.reason,
        reason_details: payload.reasonDetails,
        additional_notes: payload.additionalNotes,
        notify_member: payload.notifyMember,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useBulkCancel = (): UseMutationResult<
  BulkCancelResponse,
  Error,
  BulkCancelPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => bulkCancel(payload, accessToken), {
    onSuccess: (_, payload) =>
      Promise.all([
        queryClient.invalidateQueries(
          getAppointmentQueryKeys(accessToken).appointmentByMember(
            payload.memberUserId
          )
        ),
        queryClient.invalidateQueries(
          getMemberQueryKeys(accessToken).memberSchedulingOverview(
            payload.memberUserId
          )
        ),
        queryClient.invalidateQueries(["member-appointments"]),
        queryClient.invalidateQueries(["upcoming-first-session"]),
      ]),
  });
};
