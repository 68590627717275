import { Tooltip } from "@mui/material";

export default function RedAsterisk(): JSX.Element {
  return (
    <Tooltip title="required">
      <span
        style={{
          position: "relative",
        }}
      >
        <span
          style={{
            position: "absolute",
            fontSize: "20px",
            color: "red",
            left: "2px",
            bottom: "50%",
            transform: "translateY(50%)",
          }}
        >
          *
        </span>
      </span>
    </Tooltip>
  );
}
