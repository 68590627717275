import { AppointmentStatuses } from "@core/services/nocd-api/appointments";
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Schedule as ScheduleIcon,
} from "@mui/icons-material";
import { Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { upperCase } from "lodash/fp";

const useStyles = makeStyles((theme) => ({
  green: {
    backgroundColor: theme.palette.success.light,
    color: "white",
  },
  blue: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
  },
}));

interface AppointmentStatusChipProps {
  status: string;
}

const AppointmentStatusChip = ({
  status,
}: AppointmentStatusChipProps): JSX.Element => {
  const classes = useStyles();

  switch (status) {
    case AppointmentStatuses.CANCELLED:
      return (
        <Chip
          component="span"
          color="default"
          size="small"
          label={upperCase(status)}
          icon={<ClearIcon />}
        />
      );
    case AppointmentStatuses.SCHEDULED:
      return (
        <Chip
          component="span"
          className={classes.blue}
          color="secondary"
          size="small"
          label={upperCase(status)}
          icon={<ScheduleIcon />}
        />
      );
    case AppointmentStatuses.COMPLETED:
      return (
        <Chip
          component="span"
          className={classes.green}
          color="primary"
          size="small"
          label={upperCase(status)}
          icon={<CheckIcon />}
        />
      );
    default:
      return (
        <Chip
          component="span"
          color="default"
          size="small"
          label={upperCase(status)}
        />
      );
  }
};

export default AppointmentStatusChip;
