import { AppointmentStatuses } from "@core/services/nocd-api/appointments";
import { Chip, Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const STATUS_LIST = [
  AppointmentStatuses.SCHEDULED,
  AppointmentStatuses.COMPLETED,
  AppointmentStatuses.CANCELLED,
  AppointmentStatuses.PENDING,
];

interface MultipleSelectProps {
  appointmentStates: string[];
  setAppointmentStates: (status: string[]) => void;
}

export default function MultipleSelect({
  appointmentStates,
  setAppointmentStates,
}: MultipleSelectProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          size="small"
          multiple
          fullWidth
          value={appointmentStates}
          onChange={(event, newValue) => {
            setAppointmentStates(newValue);
          }}
          options={STATUS_LIST}
          getOptionLabel={(option) => option}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip key={option} label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} label="Status" variant="outlined" />
          )}
        />
      </Grid>
    </Grid>
  );
}
