import {
  Box,
  OutlinedTextFieldProps,
  TextField as MUITextField,
} from "@mui/material";
import { isNil } from "lodash/fp";

import RequiredIndicator from "./RequiredIndicator";

interface TextFieldProps extends Omit<OutlinedTextFieldProps, "variant"> {
  value?: string | number;
  errorMessage?: string;
  useCustomLabel?: boolean;
  hideLabel?: boolean;
  showRequiredIndicator?: boolean;
}

export default function TextField({
  name,
  value,
  errorMessage,
  helperText,
  label,
  useCustomLabel = true,
  hideLabel = false,
  showRequiredIndicator = false,
  ...restProps
}: TextFieldProps): JSX.Element {
  return (
    <>
      {useCustomLabel && !hideLabel && (
        <Box fontWeight="fontWeightMedium" mb="0.35em" fontSize="1rem">
          <>
            <span>{label}</span>
            {showRequiredIndicator ? <RequiredIndicator /> : null}
          </>
        </Box>
      )}

      <MUITextField
        error={Boolean(errorMessage)}
        helperText={errorMessage ?? helperText}
        fullWidth
        label={
          useCustomLabel || hideLabel ? undefined : (
            <>
              <span>{label}</span>
              {showRequiredIndicator ? <RequiredIndicator /> : ""}
            </>
          )
        }
        id={name}
        InputLabelProps={{
          shrink: true,
        }}
        color="primary"
        name={name}
        value={isNil(value) ? "" : `${value}`}
        variant="outlined"
        {...restProps}
      />
    </>
  );
}
