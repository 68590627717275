import { useSession } from "@core/hooks/useSession";
import { isNil } from "lodash";
import { useQuery, UseQueryOptions } from "react-query";
import invariant from "tiny-invariant";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";
import { Appointment } from "../types";

export const getAppointmentsByUserId = (
  accessToken: string | undefined,
  userId: number | undefined
): Promise<Appointment[]> => {
  invariant(userId != null, "Missing user ID");

  return api
    .get<Appointment[]>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/appointments`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useMemberAppointments = <T = Appointment[]>(
  userId: number | undefined,
  options?: UseQueryOptions<Appointment[], Error, T>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).appointmentByMember(userId),
    () => getAppointmentsByUserId(accessToken, userId),
    { enabled: !!accessToken && !isNil(userId), ...(options ?? {}) }
  );
};
