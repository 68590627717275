import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import getQueryKeys from "../query-keys";
import { Appointment } from "../types";

interface RestoreAppointmentPayload {
  id: number;
  user_id: number;
  appointment_state: string;
}

const restoreAppointment = (
  payload: RestoreAppointmentPayload,
  accessToken: string | undefined
) => {
  return api
    .post<Appointment>(
      `/v2/clinician/member_appointments/${encodeURIComponent(
        payload.id
      )}/restore_appointment`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useRestoreAppointment = (): UseMutationResult<
  Appointment,
  Error,
  RestoreAppointmentPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => restoreAppointment(payload, accessToken), {
    onSuccess: (_data, payload) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).appointmentByMember(payload.user_id)
      ),
  });
};
