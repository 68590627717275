import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../api";
import getMemberQueryKeys from "../../members/query-keys";
import { transformAPIError } from "../../utils";
import getAppointmentQueryKeys from "../query-keys";
import { SessionTypesV3 } from "../types";

interface BulkSchedulePayload {
  member_user_id: number;
  slots: {
    datetime: string;
    type: SessionTypesV3;
    duration_in_minutes: number;
    is_suggested?: boolean;
  }[];
  suggested_slots?: string[];
  clinician_email?: string;
  not_compliant_reason?: string | null;
  allow_booking_in_the_past?: boolean;
  allow_booking_over_max_das?: boolean;
}

interface BulkScheduleResponse {
  id: number;
}

const bulkSchedule = (payload: BulkSchedulePayload, accessToken: string) => {
  return api
    .post<BulkScheduleResponse>("/v1/therapy/appointments", payload, {
      headers: {
        Authorization: accessToken,
      },
    })
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useBulkSchedule = (): UseMutationResult<
  BulkScheduleResponse,
  Error,
  BulkSchedulePayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => bulkSchedule(payload, accessToken), {
    onSuccess: (_, payload) => {
      return Promise.all([
        queryClient.invalidateQueries(
          getAppointmentQueryKeys(accessToken).appointmentByMember(
            payload.member_user_id
          )
        ),
        queryClient.invalidateQueries(["member-appointments"]),
        queryClient.invalidateQueries(
          getMemberQueryKeys(accessToken).memberSchedulingOverview(
            payload.member_user_id
          )
        ),
        queryClient.invalidateQueries(["upcoming-first-session"]),
        queryClient.invalidateQueries(
          getAppointmentQueryKeys(accessToken).appointmentMetrics(
            payload.member_user_id
          )
        ),
      ]);
    },
  });
};
