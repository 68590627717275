import { SUPPORTED_COUNTRIES } from "@core/constants";
import { Countries } from "@core/types";
import getTimeZones from "@core/utils/getTimeZones";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  Select,
  SelectProps,
} from "@mui/material";
import { groupBy, toPairs } from "lodash/fp";

const timeZoneOptions = getTimeZones();

// Create a list of time zones that are in our support countries. We want to
// place these options first, so we group them separately.
const timeZoneOptionsInSupportedCountries = timeZoneOptions.filter(
  ({ countryName }) => SUPPORTED_COUNTRIES.includes(countryName as Countries)
);

const timeZoneOptionsGroupedByCountry = {
  ...groupBy("countryName", timeZoneOptionsInSupportedCountries),
  ...groupBy(
    "continentName",
    timeZoneOptions
      // We filter out time zones for our supported countries, because we append
      // them to the list of options separately. See above.
      .filter(
        ({ countryName }) =>
          !SUPPORTED_COUNTRIES.includes(countryName as Countries)
      )
      // We surface a lot of time zones to the user. To make things less
      // overwhelming we filter out continents where no one lives.
      .filter(({ continentName }) => !["Antarctica"].includes(continentName))
  ),
};

export interface TimeZoneSelectProps extends SelectProps {
  id: string;
  label: string;
  errorMessage?: string;
  hideLabel?: boolean;
  inputLabel?: boolean;
}

export function TimeZoneSelect({
  id,
  label,
  errorMessage,
  hideLabel,
  inputLabel,
  ...rest
}: TimeZoneSelectProps): JSX.Element {
  return (
    <FormControl error={Boolean(errorMessage)} fullWidth>
      {!hideLabel && (
        <FormLabel
          htmlFor={id}
          style={{ fontWeight: 500, color: "black", marginBottom: "0.35em" }}
        >
          {label}
        </FormLabel>
      )}
      {inputLabel && (
        <InputLabel htmlFor={id} shrink required>
          {label}
        </InputLabel>
      )}

      <Select
        variant="outlined"
        native
        fullWidth
        label={inputLabel ? label : ""}
        inputProps={{
          id,
        }}
        {...rest}
      >
        {toPairs(timeZoneOptionsGroupedByCountry).map(
          ([country, timezones]) => (
            <optgroup label={country} key={country}>
              {timezones.map(({ title, value: timeZoneValue }) => (
                <option value={timeZoneValue} key={timeZoneValue}>
                  {title}
                </option>
              ))}
            </optgroup>
          )
        )}
      </Select>

      {errorMessage ? <FormHelperText>Error</FormHelperText> : null}
    </FormControl>
  );
}
