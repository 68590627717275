import { Tooltip, Typography } from "@mui/material";

interface TableCellWithTooltipProps {
  value: string;
}

export default function TableCellWithTooltip({
  value,
}: TableCellWithTooltipProps) {
  if (!value) {
    return <>-</>;
  }

  return (
    <Tooltip
      title={
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {value}
        </Typography>
      }
      arrow
    >
      <span
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </span>
    </Tooltip>
  );
}
