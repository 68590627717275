import { useWarningOnExit } from "@core/hooks/useWarningOnExit";
import { FC } from "react";

interface Props {
  isDirty: boolean;
}
const UnsavedChangesWatcher: FC<Props> = ({ isDirty }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  useWarningOnExit(
    isDirty,
    "You have unsaved changes, are you sure you want to leave?"
  );

  return null;
};

export default UnsavedChangesWatcher;
