import { getTimeZones } from "@vvo/tzdb";

const getAndFormatTimeZones = () => {
  return getTimeZones()
    .map((tz) => {
      const offset = tz.currentTimeOffsetInMinutes;

      const prefix = offset >= 0 ? "+" : "-";
      const offsetAbs = Math.abs(offset);
      const hh = `${Math.floor(offsetAbs / 60)}`.padStart(2, "0");
      const ss = `${offsetAbs % 60}`.padStart(2, "0");
      const offsetFormatted = `${prefix}${hh}:${ss}`;

      // Example: (GMT-05:00) Central Time - Chicago
      const name = `(GMT${offsetFormatted}) ${tz.alternativeName} - ${tz.mainCities[0]}`;

      return {
        value: tz.name,
        title: name,
        offset,
        offsetFormatted,
        countryName: tz.countryName,
        continentName: tz.continentName,
        group: tz.group,
      };
    })
    .sort((lhs, rhs) => lhs.value.localeCompare(rhs.value));
};

export default getAndFormatTimeZones;
