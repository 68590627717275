import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { ReactNode } from "react";

import RequiredIndicator from "./RequiredIndicator";

interface CheckboxWithLabelProps extends CheckboxProps {
  label: ReactNode;
  name: string;
  helperText?: string;
  errorMessage?: React.ReactNode;
  className?: string;
  showRequiredIndicator?: boolean;
}

export default function CheckboxWithLabel({
  name,
  label,
  helperText,
  errorMessage,
  className,
  showRequiredIndicator = false,
  ...restProps
}: CheckboxWithLabelProps): JSX.Element {
  return (
    <>
      <FormControlLabel
        className={className}
        control={
          <Checkbox color="primary" id={name} name={name} {...restProps} />
        }
        slotProps={
          restProps?.size === "small"
            ? {
                typography: {
                  sx: {
                    fontSize: "0.875rem",
                  },
                },
              }
            : undefined
        }
        label={
          <>
            {label} {showRequiredIndicator ? <RequiredIndicator /> : null}
          </>
        }
      />
      <FormHelperText error={Boolean(errorMessage)}>
        {errorMessage ?? helperText}
      </FormHelperText>
    </>
  );
}
