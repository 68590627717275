import { Box, OutlinedTextFieldProps, TextField } from "@mui/material";
import { isNil } from "lodash/fp";

import RequiredIndicator from "./RequiredIndicator";

interface TextAreaProps extends Omit<OutlinedTextFieldProps, "variant"> {
  value?: string | number;
  errorMessage?: string;
  useCustomLabel?: boolean;
  showRequiredIndicator?: boolean;
}

export default function TextArea({
  name,
  value,
  errorMessage,
  helperText,
  label,
  useCustomLabel = true,
  showRequiredIndicator = false,
  ...restProps
}: TextAreaProps): JSX.Element {
  return (
    <>
      {useCustomLabel && (
        <Box fontWeight="fontWeightMedium" mb="0.35em" fontSize="1rem">
          <>
            <span>{label}</span>
            {showRequiredIndicator ? <RequiredIndicator /> : null}
          </>
        </Box>
      )}

      <TextField
        error={Boolean(errorMessage)}
        helperText={errorMessage ?? helperText}
        fullWidth
        id={name}
        InputLabelProps={{
          shrink: true,
        }}
        color="primary"
        multiline
        name={name}
        minRows={5}
        maxRows={Infinity}
        label={useCustomLabel ? undefined : label}
        value={isNil(value) ? "" : `${value}`}
        variant="outlined"
        {...restProps}
      />
    </>
  );
}
