import { useCallback } from "react";

import { Appointment } from "../types";
import { useMemberAppointments } from "./useMemberAppointments";

export const useMemberAppointment = (
  appointmentId: Appointment["id"],
  userId: number
) =>
  useMemberAppointments(userId, {
    select: useCallback(
      (data: Appointment[]) =>
        data.find((appointment) => appointment.id === appointmentId),
      [appointmentId]
    ),
  });
